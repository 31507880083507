$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient:radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ffc33b 62.5%, #5d4a1f 100%);


.outer-container_tasks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow: #9f7928 0px -2px 20px 0px; 

    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_tasks {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  padding: 3vw;
  margin: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
}
.tasks-container{    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}
.img_friends{
    width: 45px;
    margin-right: 10px;
    border-radius: 15px;
    background: linear-gradient(270deg, #ffc13e, #a68438, #a3791a);    
    padding: 10px;
    border-radius: 10px;
}
.tasks_con {
  display: flex;
  align-items: center;
  background: #282B30;
  border-radius: 10px;
  padding: 15px;
  color: white;




    .name{font-size: 15px;
        margin: 0;
        font-weight: 300;
        margin-bottom: 5px;}
}
.modal-content_tasks {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;
    box-shadow: #9f7928 0px -2px 20px 0px; 
    flex-direction: column;
    justify-content: center;
    padding: 40px;
}
.button-open-task {
    width: 100%;
    min-height: 48px;
    padding: 10px 8px;
    margin-top: 60px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.penalty{
    color: #FFA800;
    margin-top: 10px;
    text-align: center;
}
.task__description{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}
.tab_title {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.coin_icon {
  width: 40px;
  height: 40px;
}
.coin_icon_task{
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.coins_reward{
  display: flex;
  align-items: center;
}
.green {
  color: green;
  font-weight: 800;
}