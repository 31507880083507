$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient:radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ffc33b 62.5%, #5d4a1f 100%);


.outer-container_home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  box-shadow: #9f7928 0px -2px 20px 0px; 
  margin-bottom: -20px;



&::before {
  content: '';
  position: absolute;
  top: -$border-width;
  left: $border-width;
  right: $border-width;
  bottom: $border-width;
  background: $gradient;
  z-index: -1;
  border-radius: inherit; 
}
}

.container_glow_home {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  padding: 0 0 5vw 0;
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
}

.container_p40 {
  width: calc(100% - 2*$border-width); 
  height: calc(100% - 2*$border-width);
  padding: 5vw;
  margin: $border-width;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #111111; 
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  position: relative;
  z-index: 1; 
  
}

.header_pet {
  margin: 5vw;
  background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
  border-radius: 10px;
  padding: 12px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}
.row_home {
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  gap: 15px;
}
.tg_btn {
  background: #282B30;
  padding: 5px;
  border-radius: 15px;
  width: 50px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.glow_effect_container {
    width: 100vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    background: #111111;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}
.stats-coin {
  width: 30px;
  height: auto;
  margin-right: 10px;
}
.coin-count {
    font-size: 2.2rem;
    font-weight: 700;
    margin-left: -7px;
}
.energy {
  height: 100%;
  background: linear-gradient(270deg, #ffc13e, #a68438, #a3791a); 
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s ease;
}
.energy-wrapper {
  width: 90%;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 3vh;
}

.energy-icon {
  width: 25px;
  height: 25px;
}

.energy-counter {
  margin-right: 4px;
  font-weight: 700;
  font-size: 16px;
}

.energy-bar {
  width: 100%;
  height: 15px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.boost_btn_img {
  width: 25px;
  margin-bottom: 5px;
}
.boost_btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  border-radius: 10px;
  background: linear-gradient(270deg, #ffc13e, #a68438, #a3791a);
    color: white;
  text-decoration: none;
}
.league_text {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  font-weight: 300;
  z-index: 200;
}
.league_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.gameplay-coin img {
  width: 70vw;
  height: auto;
  transition: transform 0.1s ease-out;
  perspective: 1000px;
  z-index: 1;
  position: relative;
}
.header{
  flex-direction: row;
}
.settings {
  width: 23%;
  height: 100%;
  background: linear-gradient(270deg, #ffc13e, #a68438, #a3791a); 
   border-radius: 10px;
  padding: 6px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}
.setting_logo{
  border-radius: 10px;
  height: 40px;
  object-fit: cover;
  width: 40px;
}
.setting_name{
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
@media screen and (min-height: 667px) {
  .gameplay-coin img {
    width: 70vw;
   }
}
.PlayOnPhone {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  flex-direction: column;

  img{
    width: 75vw;
  }
  .text {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .subtext{
    margin-top: 10px;
  }


}
