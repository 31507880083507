$border-width: 3px;
$top-margin:30vh;
$top_margin_observe:40vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient:radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ffc33b 62.5%, #5d4a1f 100%);

.team_background{
    height: $top-margin;
    width: 100vw;
}

.teams_header_container {
  overflow: hidden;
  overflow-y: hidden;
  height: 100vh;
  position: fixed;
}


.outer-container_team {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow: #9f7928 0px -2px 20px 0px; 


  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}
.header_teams_background {
  position: absolute;
  width: 130%;
  height: 66vh;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0%;
  overflow: visible;
  z-index: -1;
  background: radial-gradient(#fedb374f 0%, #fdb93178 8%, #9f79288c 30%, #8a6e2f7a 44%, transparent 80%);
}
.container_glow_team {
    width: 100%;
    height: calc(100% - 6px);
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    margin-bottom: -6px;
}

.button_long_friend {
    width: 90%;
    min-height: 48px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.header_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
}
.outer-container_team_observe{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top_margin_observe);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow: #9f7928 0px -2px 20px 0px; 

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}
.team_background_observe{
    height: $top_margin_observe;
    width: 100vw;
}
.container_glow_team_observe {
    width: 100%;
    height: calc(100% - 6px);
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    margin-bottom: -6px;
}
.button_team {
  width: 100%;
  min-height: 50px;
  padding: 12px 8px;
  margin-top: 20px;
  color: white;
  font-weight: normal;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: linear-gradient(270deg, #ffc13e, #a68438, #a3791a);
}
.header_team {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
  font-size: 10rem;
  height: 100%;
  justify-content: space-between;
  padding: 0 40px;
}
.team_user {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  font-size: 1.1rem;
}
.users_container{
  width: 100%;
  margin-top: 20px;
  overflow-y: scroll;
}
.input_container{
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: flex-start;
  gap: 10px;
  margin: 10px 0;

  input{
    line-height: 20px;
    font-size: 1rem;
    border-radius: 5px;
    box-shadow: #9f7928 0px 0px 20px 0px;
    border: 1px solid #a56a12;
    padding: 5px;
    background: no-repeat;
    color: white;
  }
}
.header_create_team{
  position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
    align-items: center;
    font-size: 10rem;
    height: 100%;
    justify-content: space-evenly;
    padding: 0 40px;
}
.create_team_btn{
  width: 100%;
  min-height: 48px;
  padding: 12px 8px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  background: linear-gradient(222deg, #ffc13e, #a68438, #a3791a);
  font-weight: 500;
  font-size: 1.2rem;
}