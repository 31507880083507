$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
); 

.outer-container_frieds {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_friends {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}

.background_block {
    display: flex;
    align-items: center;
    background: #282B30;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
}
.button_invite {
    width: calc(100% + 32px);
    min-height: 48px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: -16px;
    font-size: 1.1rem;
}
.row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
    width: 100%;
}
.ml-10{
    margin-left:10px
}
.friends_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}
.frieds_task {
    align-items: center;
    background: #282b30;
    border-radius: 10px;
    box-shadow: 0 4px 8px #0003;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 0;

    .name{
        font-size: 15px;
        margin: 0;
        font-weight: 300;
        margin-bottom: 5px;
    }
    .flex-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .infoWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .coins{
        font-weight: 600;
    }
    .img_friends {
        display: flex;
        width: 46px;
        margin-right: 10px;
        background: linear-gradient(270deg, #ffc13e, #a68438, #a3791a);        
        padding: 5px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        font-size: 25px;
    }
    .league{
        margin-top: 5px;
        font-size: 10px;
    }
}
.tab_block_friends {
    align-items: center;
    border-radius: 10px;
    // min-height: 64px;
    padding: 16px;
    width: 100%;
    gap:16px
}

.flex_center{
    display: flex;
    align-items: center;
}

@media screen and (max-width:390px) 
{
    .container_glow_friends {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .frieds_task {
        align-items: center;
        background: #282b30;
        border-radius: 10px;
        box-shadow: 0 4px 8px #0003;
        color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 10px 0;
    
        .name{
            font-size: 13px;
            margin: 0;
            font-weight: 300;
            margin-bottom: 5px;
        }
        .flex-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .infoWrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        .coins{
            font-weight: 600;
        }
        .img_friends {
            display: flex;
            width: 40px;
            margin-right: 10px;
            background: linear-gradient(270deg, #ffc13e, #a68438, #a3791a);         
               padding: 5px;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            font-size: 25px;
        }
        .league{
            margin-top: 5px;
            font-size: 10px;
        }
    }
}