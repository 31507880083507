$border-width: 3px;
$top-margin:60vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient:radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ffc33b 62.5%, #5d4a1f 100%);

.outer_container_coming {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow: #9f7928 0px -2px 20px 0px; 

    position: absolute;
    bottom: 0;
    z-index: 1000;
  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_coming {
    width: 100%;
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #1F1F1F, #343434);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}
.glow_coming {
    position: absolute;
    width: 420px;
    height: 420px;
    background: radial-gradient(circle at center, #90408D, rgba(118, 58, 215, 0) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    overflow: visible;
    z-index: -1;
}
.img_coming {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 6px;
}
.text_coming{
    color: #FFA800;
    font-size: 10vw;
    font-weight: 700;
    position: absolute;
    top: 15%;
}