.stat-block {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-top: 10px;
    color: white;
    max-width: 800px;
}
  
.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px;
  padding: 5px 0;
  background: #333;
  border-radius: 15px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
  width: 33%;
}
  
.stat_text {
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffc13e;
}
  
  .stat-item > div {
    display: flex;
    align-items: center;
  }
  
  .stat-item img {
    width: 15px;
    height: auto;
    margin-left: 5px;
  }
  