$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient:radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ffc33b 62.5%, #5d4a1f 100%);

.modal {
    display: block;
    position: fixed;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-out;
  }
  
  .modal-container {
    width: 102%;
    height: 66%;
    background: linear-gradient(to bottom, #1F1F1F, #343434);
    border-radius: 30px 30px 0 0;
    position: absolute;
    bottom: -5px;
}


.modal-content {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ffc33b 62.5%, #5d4a1f 100%);    flex-direction: column;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.modal-image {
    border-radius: 8px;
    text-align: center;
    font-size: 4rem;
}

.modal-info {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
height: 100%;
width: 80%;
gap: 8px;
}

.modal-info p {
font-size: 16px;
}

.modal-title {
font-size: 22px;
margin-bottom: 16px;
}

.modal-button {
margin-top: 16px;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    color: #ababab;
    cursor: pointer;
    z-index: 10;
    background: #333333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}

.outer-container_boost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow: #9f7928 0px -2px 20px 0px; 

    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 5vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}

.button-long {
    width: 100%;
    min-height: 48px;
    padding: 12px 8px;
    margin-top: 60px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px -1px 20px 6px #ff9533a1;
}

.container_glow_modal{
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 5vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}


.d-flex {
    display: flex;
    align-items: center;
}
.boost_img{
max-width: 30px;}
.shop-spcial-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    gap: 12px;
  }
  
  .shop-spcial-container > div {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 6px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    text-align: left;
    gap: 8px;
    z-index: 5;
    background: linear-gradient(270deg, #ffc13e85, #a68438a6, #a3791ab0);
}
.tab-section-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
}
.tab-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 480px;
    margin: 0 auto;
  }
  
  .tab-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    min-height: 64px;
    border-radius: 10px;
    padding: 16px;
}
  
  .tab-block-title {
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  
  .tab-subblock {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 16px;
  }
  
  
.rocket_boost{
    font-size: 100px;
}
  